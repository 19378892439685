.dialog {
  width: 90%;
  max-width: 1024px;
  margin: 50px auto;
  text-align: center;
  min-height: 300px;
  height: auto;
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
  outline: 0;
  font-family: var(--font-family-base);
  overflow: hidden;

  @media (max-width: 767px) {
    width: 100%;
  }

  &--centered {
    position: absolute;
    top: ~"calc(50% - 50px)";
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__close {
    cursor: pointer;
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .link {
    color: var(--primary-700);
    transition: color 175ms cubic-bezier(0.4, 0, 0.2, 1);
    text-decoration: none;

    &--large {
      font-size: 20px;
      line-height: 28px;

      @media (max-width: 767px) {
        font-size: 14px;
      }
    }

    &--medium {
      font-size: 14px;
      line-height: 20px;
    }

    &--underline {
      &:hover {
        text-decoration: underline;
      }
    }

    &:hover {
      color: var(--primary-800);
    }
  }

  &__wrapper {
    outline: none;
  }
}

.dialog-body {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  align-items: center;

  &__title {
    margin-bottom: 64px;
    font-size: 32px;
    font-weight: bold;
    color: var(--gray-800);

    @media (max-width: 767px) {
      font-size: 28px;
      margin-bottom: 24px;
    }
  }

  &__content {
    flex: 1 0 auto;
    margin-top: 40px;
    font-weight: 300;
    min-height: 270px;

    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    @media (max-width: 320px) {
      margin-top: 0;
    }
  }

  &__text {
    margin: 0;
    padding: 0 2.5%;
    font-size: 16px;
    line-height: 24px;

    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
}

.dialog__img {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  margin-bottom: 16px;
}

.dialog-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;

  @media (max-width: 767px) {
    height: 48px;
  }
}

.ReactModal__Overlay {
  background-color: rgba(20, 27, 33, 0.8);
}

.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(20, 27, 33, 0.8);
  z-index: 21;
}

.dialog-overlay--shaded {
  &:extend(.dialog-overlay);
  background-color: rgba(20, 27, 33, 0.95);
  font-family: var(--font-family-base);

  &,
  & button {
    font-size: var(--font-size-base);
    line-height: var(--line-height-base);
  }
}

.dialog-overlay--middle-scroll {
  @media (min-width: 768px) {
    overflow-y: auto;
  }
}

.dialog--pending {
  max-width: 320px;
  min-height: 328px;

  overflow: hidden;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 8px 16px 0 rgba(0, 0, 0, 0.12);
  border-radius: var(--border-radius-md);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  background: var(--white);

  .dialog {
    &__content {
      flex: 1;
      width: 100%;
      box-sizing: border-box;
      padding: 40px 24px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: center;
    }

    &__title {
      font-weight: 500;
      text-align: center;
      color: #0c1014;
      font-size: 16px;
      line-height: 28px;
      margin: 0;
    }

    &__description {
      text-align: center;
      color: var(--gray-500);
      font-size: var(--font-size-base);
      line-height: 24px;
      margin: 0;

      a {
        color: var(--gray-800);
      }
    }
  }

  * + .dialog__description {
    margin-top: 16px;
  }

  // Align buttons border-radius with dialog
  .button-group--stick-top > .button {
    border-radius: var(--border-radius-md);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.dialog--pending {
  min-height: auto;
}

.dialog--result {
  min-width: 380px;
  min-height: 280px;

  .dialog__content {
    border: 1px solid var(--gray-300);
    border-radius: var(--border-radius-md);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 0;
  }

  .dialog__img--success {
    &:extend(.dialog__img);
    background-image: url('/images/purchase-success.svg');
  }

  .dialog__img--failure {
    &:extend(.dialog__img);
    background-image: url('/images/purchase-failure.svg');
  }

  @media (max-width: 767px) {
    min-width: 180px;
    max-width: 288px;
  }
}

// *-appear and *-appear-done suffixes are added
// automatically via CSSTransition component
.dialog--animated-appear {
  transform: scale(0.5);
  opacity: 0;

  &.dialog--centered {
    transform-origin: 0 0;
    transform: scale(0.5) translate(-50%, -50%);
  }
}

.dialog--animated-appear-done {
  transform: scale(1);
  opacity: 1;
  transition: 0.3s all ease-out;

  &.dialog--centered {
    transform-origin: 0 0;
    transform: scale(1) translate(-50%, -50%);
  }
}

// Common classes to perform overlay fade transitions
// via ReactModal's `overlayClassName` prop
.dialog-overlay--fade {
  opacity: 0;
  transition: opacity 0.2s cubic-bezier(0, 0, 0.2, 1);

  &.dialog-overlay--after-open {
    opacity: 1;
  }
}
