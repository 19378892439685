@import "mixins";


.animated-slow {
  .vendor(animation-duration, 0.4s);
  .vendor(animation-fill-mode, both);
}

@keyframes wiggle {
  0%, 100% {
    transform: translate3d(0, 0, 0);
  }

  20%, 60% {
    transform: translate3d(-10px, 0, 0);
  }

  40%, 80% {
    transform: translate3d(10px, 0, 0);
  }
}

@-webkit-keyframes wiggle {
  0%, 100% {
    -webkit-transform: translate3d(0, 0, 0);
  }

  20%, 60% {
    -webkit-transform: translate3d(-10px, 0, 0);
  }

  40%, 80% {
    -webkit-transform: translate3d(10px, 0, 0);
  }
}

@-moz-keyframes wiggle {
  0%, 100% {
    -moz-transform: translate3d(0, 0, 0);
  }

  20%, 60% {
    -moz-transform: translate3d(-10px, 0, 0);
  }

  40%, 80% {
    -moz-transform: translate3d(10px, 0, 0);
  }
}

@-o-keyframes wiggle {
  0%, 100% {
    -o-transform: translate3d(0, 0, 0);
  }

  20%, 60% {
    -o-transform: translate3d(-10px, 0, 0);
  }

  40%, 80% {
    -o-transform: translate3d(10px, 0, 0);
  }
}

.wiggle {
  .vendor(animation-name, wiggle);
}
