@import "../../node_modules/font-awesome/less/font-awesome.less";
@import "transitions";
@import (inline) "../../node_modules/ladda/dist/ladda.min.css";
@import "../../node_modules/@yola/yonav/src/less/index";
@import (inline) "../../node_modules/@yola/ws-ui/dist/styles.css";
@import (inline) "../../node_modules/@yola/webapp-plugins/dist/styles.css";
@import "dialog";
@import "ybot-auth-massage";

@primary: #17ade5;

body {
  margin: 0;
  font-family: 'Helvetica Neue', sans-serif;
}

h1 {
  font-family: 'Helvetica Neue', sans-serif;
  font-weight: 100;
  color: @primary;
  display: inline-block;
  float: right;
  margin: 0.1em 0.5em 0 0;
  padding: 0;
}

.page-container {
  position: fixed;
  top: 47px;
  bottom: 0;
  left: 0;
  right: 0;
  background: #eee;
  &.scrollable {
    overflow: auto;
  }
}

iframe {
  width: 100%;
  height: 100%;
  border: none;
  margin-top: 0;
}

.page-view {
  width: 100%;
}

.sitewit-view {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.ios-device .sitewit-view {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

.pricing-view {
  width: 100%;
  height: 100%;
  background: #ccc;
}

.instructions {
  width: 65%;
  margin: 1rem auto 0 auto;
  padding: 2rem;
  text-align: center;

  h2 {
    margin-top: 0;
    font-weight: 200;
  }

  svg {
    vertical-align: top;
  }

  .take-action {
    background: #00BBF2;
    color: #000;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.07em;
    padding: 10px;
    border-radius: 10px;
    text-decoration: none;
    display: inline-block;
    margin-bottom: 1rem;
  }

  .right {
    margin-left: 0.75rem;
  }

  .take-action:hover {
    background: darken(#00BBF2, 5%);
  }

  .fine-print {
    font-size: 0.8rem;
  }
}

.loading-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display:flex;
  justify-content: center;
  align-items: center;
  background-color: #666;
  color: white;
  box-shadow: inset 0 0 10px #222;
}

.fadeout {
  opacity: 0;
  transition: opacity 0.25s linear;
}

.yodeling-hidden {
  display: none;
}

.page-view {
  &--invisible-header {
    .universal-top {
      visibility: hidden;;
    }

    .ws-nav-bar-container {
      display: none;
    }
  }

  &--ws-user {
    .universal-top {
      display: none;;
    }
  }
}

.ws-nav-bar {
  position: fixed;
  top: 0;
  width: 100%;
  height: 46px;
  border-bottom: 1px solid #dadde0;
  display: flex;
  justify-content: space-between;
  font-family: 'Roboto', sans-serif;

  &__navigation,
  &__content {
    display: flex;
    align-items: center;
    padding: 0 30px;
  }

  &__navigation {
    border-right: 1px solid #dadde0;
  }

  &__editor-link {
    color: #000;
    text-decoration: none;
    position: relative;
    padding-left: 20px;

    &:hover,
    &:focus,
    &:active {
      color: #000;
    }

    &:before {
      position: absolute;
      content: '\003C';
      left: 0;
    }
  }
}
