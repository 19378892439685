.y-bot-auth-massage {
  position: fixed;
  z-index: 1001;
  width: 100%;
  background: var(--success-800);
  color: var(--white);
  font-size: var(--font-size-base);
  font-family: var(--font-family-base);
  line-height: var(--line-height-base);
  text-align: center;
  padding: 10px 45px;
  box-sizing: border-box;

  svg {
    position: absolute;
    right: 10px;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
  }
}
